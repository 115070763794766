.hero {
  margin-top: 2.5em;
  padding: 1em;
  background: var(--color-gradient);
  color: var(--color-lighter);
  text-align: center;

  img {
    display: block;
    width: 100%;
    max-width: 350px;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 4em;
  }

  &__actions {
    box-sizing: border-box;
    max-width: $max-width;
    margin: 2em auto 4em;
    padding: 0 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: 720px) {
      flex-direction: column;
    }
  }

  &__action {
    margin: 0 .5em 1em;
    width: 9em;
    text-align: center;
    text-decoration: none;
    border: 2px solid var(--color-lighter);
    color: var(--color-lighter);
    padding: .5em 2em;
    font-weight: 400;

    &--primary {
      background: var(--color-lighter);
      color: var(--color-darker);
    }

    &--disabled {
      opacity: .5;
      cursor: default;
      user-select: none;
    }

    &:not(&--disabled):hover {
      transform: translateY(1px);
    }
  }

  &__link {
    color: var(--color-lighter);
  }
}