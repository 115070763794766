:root {
    --link-color: #273968;
    --main-background-color: hsl(0, 0%, 90%);
    --main-text-color: #151515;
    --color-gradient: linear-gradient(90deg, #5e4779, #b488cc, #5e4779);
    --color-darker: #241545;
    --color-dark: #273968;
    --color-primary: #7953e0;
    --color-light: #f1baff;
    --color-lighter: #fafafa;
    --color-code: #273968;
    --color-code-bg: #a47eaf;
}

// Dark theme values

@media (prefers-color-scheme: dark) {
    :root {
        --link-color: #f1baff;
        --main-background-color: hsl(0, 0%, 12%);
        --main-text-color: hsl(0, 0%, 85%);
        --color-gradient: linear-gradient(90deg, #241545, #795e87, #241545);
        --color-darker: #1f123a;
        --color-dark: #192543;
        --color-primary: #553a9f;
        --color-light: #d9a3e7;
        --color-lighter: #fafafa;
        --color-code: #c3bbe6;
        --color-code-bg: #85688e4e;
    }
}