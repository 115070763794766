@import 'colors.scss';

$max-width: 1024px;


html,
body {
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  margin: 0;
}

a {
  color: var(--link-color);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

.body {
  box-sizing: border-box;
  max-width: $max-width;
  margin: 2em auto 4em;
  padding: 0 1em;
}

img {
  width: 100%;
}


.discordBadge {
  width: 35%;
}

.hidden-link {
  text-decoration: none;
  color: inherit;
}

.center-video {
  box-sizing: border-box;
  max-width: 1280px;
  margin: 2em auto 4em;
  padding: 0 1em;
}

.video-frame {
  width: 100%;
  aspect-ratio: 16 / 9;
}

code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  line-height: normal;
  background: var(--color-code-bg);
  color: var(--color-code);
  border-radius: 3px;
  font-size: 0.8em;
  padding: 0.2em 0.4em;
}