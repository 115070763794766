@import "typography.scss";
@import "base.scss";

@import "components/footer.scss";
@import "components/hero.scss";
@import "components/nav.scss";
@import "components/posts.scss";
@import "components/release.scss";
@import "components/versions.scss";
@import "components/side-figure.scss";
@import "components/social.scss";