.double-figure {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  .side-figure {
    &__picture, &__video {
      width: 100%;
    }
  }
}

.side-figure {
  margin: 1em 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  &__video {
    video {
      width: 100%;
    }
  }
  &__picture {
    flex-shrink: 0;
    width: 10em;
    max-width: 100%;
    margin: auto;
  }
  &__caption {
    width: 100%;
    opacity: .5;
    font-size: .8em;
    font-weight: 400;
    margin-top: .25em;
  }
  &__text {
    flex: 1;
    margin-left: 1em;
    p {
      margin: 0;
    }
  }
  &--right {
    flex-direction: row-reverse;
  }
  &--right &__text {
    margin-left: 0;
    margin-right: 1em;
  }
}

@media (max-width: 800px) {
  .side-figure {
    flex-direction: column;
    &__caption {
      margin-bottom: 1em;
    }
    &__text {
      margin: 0;
    }
  }
}