.nav {
  font-size: .8em;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--color-darker);
  color: var(--color-lighter);
  z-index: 1;

  &__container {
    box-sizing: border-box;
    width: 100%;
    max-width: $max-width;
    padding: .5em 1em;
    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__brand {
    width: 3.25em;
  }

  &__version {
    @media (max-width: 600px) {
      display: none;
    }

    margin-left: 1em;
  }

  &__spacer {
    flex: 1;
  }

  &__link {
    margin-left: 1em;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: var(--color-light);
  }
}