.release {
  box-sizing: border-box;
  max-width: $max-width;
  margin: 1em auto 0;
  padding: 0 1em;

  &__title {
    margin-bottom: 1em;
    font-weight: 500;
    font-size: 1.2em;
  }

  &__downloads {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 720px) {
    &__downloads {
      flex-direction: column;
    }
  }

  &__hash {
    font-size: .75em;
    margin-bottom: 0.0em;
  }
  
  &__mirror {
    font-size: .7em;
    margin-bottom: 0.5em;
    font-style: italic;
  }

  &__eula {
    font-size: .7em;
    margin-bottom: 0.5em;
  }

  .hero__action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    svg {
      width: 1.5em;
      margin-right: .5em;

      path {
        color: var(--color-darker);
      }
    }
  }
}