.footer {
  background: var(--color-darker);
  color: var(--color-lighter);
  font-size: .8em;

  &__container {
    box-sizing: border-box;
    width: 100%;
    max-width: $max-width;
    padding: .5em 1em;
    margin: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__spacer {
    flex: 1;
  }

  &__link {
    margin-left: 1em;
    color: var(--color-lighter);
  }

  svg path {
    fill: var(--color-lighter);
  }

  #twitter {
    width: 2em;
  }

  #facebook {
    width: 1.5em;
  }

  #rss {
    width: 1.25em;
    margin-left: .5em;
  }

  a {
    color: var(--color-lighter);
  }
}